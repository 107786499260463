<template>
  <div>
    <b-container>
      <b-row
        v-show="!webcamSetup"
        align-h="center"
        class="py-3 py-sm-4 py-md-5"
      >
        <b-col class="col-auto col-sm-9 col-lg-6 pt-lg-5">
          <div>
            <b-card
              body-class="p-3"
              style="border-radius: 0"
              border-variant="white"
            >
              <div class="position-relative">
                <video
                  class="webcam-border video"
                  :class="faceDetected ? 'active' : 'inactive'"
                  playsInline="true"
                  ref="preview"
                  id="video"
                ></video>

                <div class="overlay" :class="{ inactive: !faceDetected }"></div>
              </div>

              <b-row class="mt-2" align-v="center" align-h="between">
                <b-col cols="auto pr-2">
                  <b-button
                    class="text-small py-2 px-2 text-uppercase"
                    variant="secondary"
                    @click="onQuit"
                  >
                    {{ $t("button.dontProceed") }}
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    :disabled="!faceDetected"
                    @click="goToViewer()"
                    size="sm"
                    class="text-small py-2 px-4 my-2 text-uppercase"
                    >{{ $t("button.next") }}</b-button
                  >
                </b-col>
              </b-row>
              <b-row align-v="center" align-h="between">
                <b-col cols="auto">
                  <div v-show="!faceDetected">
                    <div class="m-0 p-0" style="font-size: 1rem">
                      {{ $t("page.viewer.facingCameraMsg") }}
                    </div>

                    <small class="text-primary">
                      {{ $t("page.viewer.noFaceDetetionMsg") }}
                    </small>
                  </div>
                </b-col>
              </b-row>

              <canvas
                width="480"
                height="360"
                ref="canvas"
                id="canvas"
              ></canvas>
            </b-card>
            <b-card
              class="mt-2"
              body-class="p-3"
              style="border-radius: 0"
              border-variant="white"
            >
              <div
                class="text-center text-medium pb-3 text-primary font-weight-bold text-uppercase"
              >
                {{ $t("page.viewer.recommendations") }}
              </div>
              <b-row align-h="center" class="px-2">
                <b-col class="text-center p-0" cols="3"
                  ><div class="mb-2">{{ $t("page.viewer.audioOn") }}</div>
                </b-col>
                <b-col class="text-center p-0" cols="3">
                  <div class="mb-2">{{ $t("page.viewer.stayFramed") }}</div>
                </b-col>

                <b-col class="text-center p-0" cols="3"
                  ><div class="mb-2">{{ $t("page.viewer.noLunch") }}</div>
                </b-col>
                <b-col class="text-center p-0" cols="3"
                  ><div class="mb-2">{{ $t("page.viewer.noTalk") }}</div>
                </b-col>
              </b-row>
              <b-row align-h="center" class="px-2">
                <b-col class="text-center p-0" cols="3">
                  <img width="40" src="../assets/img/Audio.svg" />
                </b-col>
                <b-col class="text-center p-0" cols="3">
                  <img width="40" src="../assets/img/Framed.svg" />
                </b-col>
                <b-col class="text-center p-0" cols="3">
                  <img width="40" src="../assets/img/Drink.svg" />
                </b-col>
                <b-col class="text-center p-0" cols="3">
                  <img width="40" src="../assets/img/Talk.svg" />
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <router-view
      v-if="webcamSetup"
      @startRecording="startRecording"
      @stopRecording="stopRecording"
      @finishedRecording="finishedRecording"
    ></router-view>
  </div>
</template>

<script>
import * as faceapi from "face-api.js";

import { mapGetters } from "vuex";

export default {
  name: "App",

  data() {
    return {
      preview: {},
      context: null,
      canvas: {},
      webcamSource: null,
      detectionInterval: null,
      faceDetected: false,
      images: [],
      isRecording: false,
    };
  },

  mounted() {
    this.$store.commit("stimulus/SET_UPLOAD_IMAGES_COMPLETED", false);
    this.$store.commit("stimulus/SET_UPLOAD_ANSWERS_COMPLETED", false);
    this.$store.commit("settings/SET_WEBCAM_SETUP", false);
    window.addEventListener("beforeunload",this.handleBeforeUnload) // prevent user from accidentally closing the window

    faceapi.nets.tinyFaceDetector.loadFromUri("/models").then(() => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: "user",
            },
          })
          .then((stream) => {
            this.webcamSource = stream;
            this.setupWebcam();
          })
          .catch(() => {});
      }
    });
  },
  beforeDestroy(){
    window.removeEventListener('beforeunload',this.handleBeforeUnload)
  },

  computed: {
    ...mapGetters("settings", {
      environment: "getEnvironment",
      respondentId: "getRespondentId",
      webcamSetup: "getWebcamSetup",
    }),
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
      viewer: "getViewer",
      questions: "getQuestions",
      endLinks: "getEndLinks",
    }),
  },

  methods: {
    handleBeforeUnload(event){
      event.preventDefault();
      const confirmationMessage="Are you sure you want to leave?\nYour participation will not count if you leave now!"
      event.returnValue = confirmationMessage; // some browsers require returnValue to be set
      return confirmationMessage;
    },
    
    onQuit() {
      window.removeEventListener('beforeunload',this.handleBeforeUnload)
      this.$store.commit("stimulus/SET_PERMISSION", false);
      if (this.endLinks.screenout) window.location = this.endLinks.screenout;
    },

    setupWebcam() {
      this.preview = this.$refs.preview;
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext("2d");

      this.preview.srcObject = this.webcamSource;

      this.preview.addEventListener("canplay", () => {
        this.detectionInterval = setInterval(() => {
          faceapi
            .detectSingleFace(
              this.preview,
              new faceapi.TinyFaceDetectorOptions({
                scoreThreshold: 0.4,
              })
            )
            .then((detection) => {
              if (detection) {
                this.faceDetected = true;
              } else {
                this.faceDetected = false;
              }
            });
        }, 500);
      });

      this.preview.play();
    },

    hideWebcam() {
      clearInterval(this.detectionInterval);
      this.$store.commit("settings/SET_WEBCAM_SETUP", true);
    },

    goToViewer() {
      this.hideWebcam();
      this.$router
        .push({
          name: this.viewer,
          params: { pathMatch: this.environment },
        })
        .catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
    },

    drawImageAndSendTick({ position, stimulus, replay, timestamp, phase }) {
      if (this.preview.videoWidth > this.preview.videoHeight)
        this.context.drawImage(this.preview, 0, 0, 480, 360);
      else this.context.drawImage(this.preview, 0, 0, 360, 480);
      const url = this.canvas.toDataURL();
      const image = this.base64ToFile(url, position, replay);
      this.images.push({
        snapshot: image,
        tick: position,
        timestamp: timestamp,
        replay: replay,
        phase: phase,
      });
      this.$store.dispatch("stimulus/updateProgress", {
        tick: position,
        stimulusToken: stimulus.stimulusId,
        respondentId: this.respondentId,
      });
    },

    startRecording({
      position,
      stimulus,
      replay = 0,
      timestamp = 0,
      phase = 0,
    }) {
      if (this.isRecording === true) return;
      this.isRecording = true;
      this.images = [];
      this.drawImageAndSendTick({
        position,
        stimulus,
        replay,
        timestamp,
        phase,
      });
      ++position;
      timestamp += 1000;
      this.recordingInterval = setInterval(() => {
        this.drawImageAndSendTick({
          position,
          stimulus,
          replay,
          timestamp,
          phase,
        });
        ++position;
        timestamp += 1000;
      }, 1000);
    },

    stopRecording({ stimulus }) {
      if (this.isRecording === false) return;
      this.isRecording = false;
      clearInterval(this.recordingInterval);
      this.$store.dispatch("stimulus/addStimulusImages", {
        stimulusToken: stimulus.stimulusId,
        images: this.images,
      });
      this.images = [];
    },

    finishedRecording() {
      this.stopWebcamTracks();
      this.uploadUserImages();
      this.handleRedirectionAfterWatch();
    },

    base64ToFile(dataURI, tick, replay) {
      var arr = dataURI.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const filename = replay
        ? `respondent_${this.respondentId}_${replay}_${tick}.png`
        : `respondent_${this.respondentId}_${tick}.png`;
      return new File([u8arr], filename, {
        type: mime,
      });
    },

    stopWebcamTracks() {
      this.webcamSource.getTracks().forEach(function (track) {
        track.stop();
      });
    },

    async handleRedirectionAfterWatch() {
      if (this.questions.length === 0) {
        this.uploadAnswers(this.stimulus);
        this.$router.push({
          name: "done",
          params: {
            pathMatch: this.environment,
          },
        });
      } else {
        this.$router.push({
          name: "questions",
          params: {
            pathMatch: this.environment,
          },
        });
      }
    },

    async uploadUserImages() {
      if (this.viewer === "standard") {
        for (let index = 0; index < this.stimulus.length; ++index) {
          const currentStimulus = this.stimulus[index];
          await this.$store.dispatch("stimulus/uploadImages", {
            stimulusToken: currentStimulus.stimulusId,
            images: currentStimulus.images.map((image) => image.snapshot),
            respondentId: this.respondentId,
          });
        }
      } else {
        for (let index = 0; index < this.stimulus.length; ++index) {
          const currentStimulus = this.stimulus[index];
          await this.$store.dispatch("stimulus/uploadImagesV2", {
            stimulusToken: currentStimulus.stimulusId,
            images: currentStimulus.images,
            respondentId: this.respondentId,
          });
        }
      }
      this.$store.commit("stimulus/SET_UPLOAD_IMAGES_COMPLETED", true);
    },

    async uploadAnswers() {
      try {
        let response = null;

        this.$store.commit("stimulus/SET_ANSWERS", []);

        for (let index = 0; index < this.stimulus.length; ++index) {
          response = await this.$store.dispatch("stimulus/uploadAnswers", {
            stimulusToken: this.stimulus[index].stimulusId,
          });
        }

        this.$store.commit(
          "stimulus/SET_COMPLETE_LINK",
          response.endLinks.complete
        );

        this.$store.commit("stimulus/SET_UPLOAD_ANSWERS_COMPLETED", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);

  width: 100% !important;
  height: auto !important;
}

#canvas {
  display: none;
}
#webcam {
  background-color: #000000;
}

.overlay {
  position: absolute;
  width: 150px;
  height: 200px;
  border: 1px solid white;
  opacity: 0.2;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inactive {
  border-color: var(--primary);
  opacity: 1;
}

.active {
  border-color: green;
}

.text-small {
  font-size: 0.7em !important;
}
.text-medium {
  font-size: 1.2em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
